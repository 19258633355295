
import { defineComponent, onMounted } from 'vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Header from '@/views/new-design/pages/ViewTenantReview/Header.vue';
import History from '@/views/new-design/pages/ViewTenantReview/History.vue';
import Report from '@/views/new-design/pages/ViewTenantReview/Report.vue';
import AgentDetails from '@/views/new-design/pages/ViewTenantReview/AgentDetails.vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions, Mutations } from '@/store/enums/TenantReviewEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { Tenant } from '@/models/TenantModel';
import { numberFormat } from '@/utils/text';
import store from '@/store';
import {
  Actions as TActions,
  Getters as TGetter,
} from '@/store/enums/TenantEnums';
import GoogleMap from '@/views/new-design/components/GoogleMap.vue';
import {
  Actions as BusinessActions,
  Getters as BusinessGetters,
  Mutations as BusinessMutations,
} from '@/store/enums/BusinessEnums';
import image from '@/utils/image';
import text from '@/utils/text';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import {
  Actions as UserActions,
  Mutations as UserMutations,
} from '@/store/enums/UserEnums';
import NoReview from '@/views/new-design/pages/ViewTenantReview/NoReview.vue';

const initialValues = {
  id: '',
  tenant_id: '',
  tenant: {} as Tenant,
  reference_no: '',
} as {} as unknown as TenantReview;

export default defineComponent({
  components: {
    Navigator,
    Header,
    History,
    Report,
    AgentDetails,
    GoogleMap,
    BusinessLogo,
    NoReview,
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    selectedRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tenant: {} as unknown as Tenant,
    tab: 'report',
    logoData: null,
  }),
  async mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    this.loading = true;
    this.reviewDetailLoading = true;
    setCurrentPageTitle('Get Tenant Reference');

    this.setRouteRefNo();

    await this.loadTenantReviewByRefNo();

    this.setTenantId();
    this.setTenant();

    await this.loadTenantReviews();

    this.setCurrentTenantReview();

    this.computeAverageRating();

    this.fetchCurrentReviewAvatar();

    (this.$refs.googleMap as any).initializeMap();
  },
  computed: {
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenantReviews: Getters.GET_ALL_TENANT_REVIEWS,
      getTenant: TGetter.GET_TENANT,
      getBusinessLogo: BusinessGetters.GET_AGENT_BUSINESS_LOGO,
    }),
    tenantRecord() {
      return this.tenant;
    },
    currentAddress() {
      return `${(this.currentTenantReview as any)?.address_line}, ${
        (this.currentTenantReview as any)?.city
      }, ${(this.currentTenantReview as any)?.post_code}, ${
        (this.currentTenantReview as any)?.state
      }`;
    },
    headerColor() {
      if (!(this.currentTenantReview as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (
        (this.currentTenantReview as any)?.user?.business_color ?? '#4070ea'
      );
    },
    hasLogo() {
      return (
        (this.currentTenantReview as any)?.user?.business_logo &&
        (this.currentTenantReview as any)?.user?.business_logo.trim() != ''
      );
    },
  },
  watch: {
    currentTenantReview: {
      handler(value) {
        store.commit(Mutations.SET_AGENT_AVATAR, '');

        if (value) {
          this.displayLogo();
          this.fetchAgentAvatar();

          this.$nextTick(() => {
            (this.$refs.googleMap as any).searchMap();
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchTenantReviews: Actions.FETCH_ALL_TENANT_REVIEWS,
      fetchTenantReview: Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO,
      fetchTenant: TActions.FETCH_TENANT,
      fetchBusinessLogo: BusinessActions.FETCH_AGENT_BUSINESS_LOGO,
      fetchTenantAvatar: UserActions.FETCH_AVATAR,
    }),
    setTenantId() {
      this.tenantId =
        typeof this.$route.params.tenantId !== 'undefined'
          ? this.$route.params.tenantId
          : (this.currentTenantReview.tenant_id as string);
    },
    setCurrentTenantReview() {
      if (typeof this.currentRefNo === 'undefined' || !this.currentRefNo) {
        if (this.tenantReviews.length) {
          this.currentTenantReview = this.tenantReviews[0];
        }
        return;
      }

      // fetch tenant reviews by refno
      this.currentTenantReview = this.tenantReviews.filter((tenantReview) => {
        if (tenantReview.reference_no == this.currentRefNo) {
          return tenantReview;
        }
      })[0];

      this.loading = false;
      this.reviewDetailLoading = false;
    },
    setRouteRefNo() {
      this.currentRefNo = (
        typeof this.$route.params.refNo !== 'undefined'
          ? this.$route.params.refNo
          : ''
      ) as string;
    },
    async loadTenantReviewByRefNo() {
      this.loading = true;
      this.reviewDetailLoading = true;

      if (!this.currentRefNo) return;

      // fetch tenant reviews by refno
      await this.fetchTenantReview(this.currentRefNo);
    },
    async setTenant() {
      if (this.$route.params.tenantId) {
        await this.fetchTenant(this.tenantId);
        this.tenant = this.getTenant;
      } else {
        this.tenant = this.tenantReview.tenant;
      }
    },
    async loadTenantReviews() {
      // fetch tenant reviews by tenant id
      const id = this.$route.params.tenantId ?? this.tenantReview.tenant_id;
      await this.fetchTenantReviews(id);
    },
    handleSelectRentalHistory(values) {
      this.loading = true;
      this.reviewDetailLoading = true;
      this.tab = 'report';

      setTimeout(() => {
        this.currentRefNo = values;
        this.setCurrentTenantReview();
      }, 1000);
    },
    computeAverageRating() {
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        let sumRating = 0;
        this.tenantReviews.map((review: TenantReview) => {
          sumRating = sumRating + parseFloat(review.average_rating);
        }, 0);

        const average = `${sumRating / this.tenantReviews.length}`;
        this.averageRating = numberFormat(average);
      }

      this.loading = false;
      this.reviewDetailLoading = false;
    },
    async displayLogo() {
      if (!(this.currentTenantReview as any)?.user.business_logo) return;

      const mime = image.imageMIME(
        text.fileExtension(
          (this.currentTenantReview as any)?.user.business_logo
        )
      );

      return await this.fetchBusinessLogo(
        (this.currentTenantReview as any)?.user?.business_id
      ).then(async (logoRawImageData) => {
        const imageData = image.rawImageToDataUrl(logoRawImageData, mime);
        store.commit(BusinessMutations.SET_AGENT_BUSINESS_LOGO, imageData);
      });
    },
    async fetchCurrentReviewAvatar() {
      return await this.fetchTenantAvatar(this.tenant?.user?.id).then(
        async (logoRawImageData) => {
          const imageData = image.rawImageToDataUrl(logoRawImageData, 'jpg');
          store.commit(UserMutations.SET_AVATAR, imageData);
        }
      );
    },
    async fetchAgentAvatar() {
      return await this.fetchTenantAvatar(
        (this.currentTenantReview as any)?.user?.id
      ).then(async (logoRawImageData) => {
        const imageData = image.rawImageToDataUrl(logoRawImageData, 'jpg');
        store.commit(Mutations.SET_AGENT_AVATAR, imageData);
      });
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
      const googleMapScript = document.createElement('SCRIPT');
      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyAGX7VifDi6W0AlZhM4Wkv31D2cm08s7wA&callback=initMap&libraries=places`
      );
      googleMapScript.setAttribute('defer', '');
      googleMapScript.setAttribute('async', '');
      document.head.appendChild(googleMapScript);
    });
  },
});
