
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import EmailReportModal from '@/views/new-design/pages/ViewTenantReview/EmailReport.vue';
import toasts from '@/utils/toasts';
import PDFGenerator from '@/views/new-design/pages/ViewTenantReview/PDFGenerator.vue';
import LedgerReport from '@/views/new-design/pages/ViewTenantReview/Report/Ledger.vue';
const badgeColor = {
  yes: 'badge-yes',
  no: 'badge-no',
  'n/a': 'badge-not-applicable',
};

export default defineComponent({
  name: 'rental-review-details-section',
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    currentTenantReview: {
      type: Object,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    reviewDetailLoading: {
      type: Boolean,
      required: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    EmailReportModal,
    PDFGenerator,
    LedgerReport,
  },
  data: () => ({
    downloading: false,
    sendReport: false,
    sendingReport: false,
    downloadingReport: false,
    pdfFile: '',
  }),
  computed: {
    ...mapGetters({
      ledgerFile: Getters.GET_TENANT_REVIEW_LEDGER_FILE,
      actionError: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
    }),
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    address() {
      return `${(this.currentTenantReview as any)?.address_line}, ${
        (this.currentTenantReview as any)?.city
      }, ${(this.currentTenantReview as any)?.post_code}, ${
        (this.currentTenantReview as any)?.state
      }`;
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },
  },
  methods: {
    ...mapActions({
      fetchTenantLedger: Actions.FETCH_TENANT_REVIEW_LEDGER,
      sendToEmails: Actions.SEND_TENANT_REVIEW_TO_EMAILS,
    }),
    getColor(answer = '' as string) {
      return badgeColor[answer.toLowerCase()];
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return numberFormat(rating);
    },
    async download(type) {
      if (type == 'ledger') {
        this.downloadLedger();
      } else if (type == 'report') {
        this.downloadingReport = true;
        (this.$refs.pdfReport as any).generatePDf();
      }
    },

    async handleSendReport() {
      (this.$refs.senfPdfReport as any).generatePDf();
      this.sendReport = true;
    },

    downloadLedger() {
      if (this.downloading) return;
      this.downloading = true;

      // for indicator purposes only
      setTimeout(() => {
        this.downloading = false;
      }, 2000);

      this.fetchTenantLedger({
        tenantId: this.currentTenantReview?.tenant_id,
        refId: this.currentTenantReview?.reference_no,
      }).then(() => {
        var fileURL = window.URL.createObjectURL(new Blob([this.ledgerFile]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.currentTenantReview?.reference_no}.pdf`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    async handleBeforeDownload(pdf) {
      this.pdfFile = pdf;
    },

    async emailReport(emails) {
      const payload = {
        refNo: this.currentTenantReview?.reference_no,
        emails: Object.values(emails),
        report: this.pdfFile,
      };

      this.sendingReport = true;
      await this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          (this.$refs.emailReport as any).clear();
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.sendingReport = false;
          this.sendReport = false;
        });
    },
  },
});
