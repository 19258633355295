
import { defineComponent } from 'vue';
import router from '@/router';
import timeUtil from '@/utils/time';
import maskUtil from '@/utils/mask';
import { Tenant } from '@/models/TenantModel';
import { capitalize, numberFormat } from '@/utils/text';
import Avatar from '@/views/new-design/pages/ViewTenantReview/Avatar.vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/enums/UserEnums';

export default defineComponent({
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    tenant: {
      type: Object,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    Avatar,
  },
  data: () => ({
    currentTenant: {} as unknown as Tenant,
  }),
  methods: {
    submitTenantReference(id) {
      router.push({
        name: 'submit-tenant-review',
        query: {
          tenant: id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      avatarSrc: Getters.GET_AVATAR,
    }),
    rate() {
      return numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    fullName() {
      const fname = capitalize(`${this.currentTenant.first_name}`);
      const lname = capitalize(`${this.currentTenant.last_name}`);

      return `${fname} ${lname}`;
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },
  },
  watch: {
    tenant(values) {
      this.currentTenant = {
        ...(this.$props.tenant as Tenant),
        ...{ date_of_birth: timeUtil.dateFormat(values.date_of_birth) },
      };
    },
  },
});
