
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
// import StreetView from '@/views/new-design/components/GoogleMapStreetView.vue';

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory'],
  // components: { StreetView },
  props: {
    currentTenantReview: {
      type: Array,
      default: () => undefined,
    },
    tenantReviews: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
  },
  methods: {
    isCurrent(review) {
      return (this.currentTenantReview as any)?.id == review.id;
    },
    selectRentalHistory(referenceNo) {
      this.$emit('selectRentalHistory', referenceNo);
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    toDecimal(number) {
      return numberFormat(number, 1);
    },
    getAddress(review) {
      return `${review?.address_line}, ${review?.city}, ${review?.post_code}, ${review?.state}`;
    },
    getDates(review) {
      return `${this.dateFormat(review.move_in_date)} - ${this.dateFormat(
        review.move_out_date
      )}`;
    },
  },
});
